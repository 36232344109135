import React from 'react';
import AbstractComponent from '../../AbstractComponent';
import CarrinhoContent from './CarrinhoContent';
import CarrinhoButton from './CarrinhoButton';

import './Carrinho.css';

class Carrinho extends AbstractComponent {   
    constructor(props) {
        super(props);          
    
        this.state = {            
            showCartMinimized: true
        }        
    }    
    
    onUpdateCart(cart) {                
        if((cart.carrinho_item_quantidade.length + cart.carrinho_item_peso.length) === 0)             
            this.setState({showCartMinimized: true});        

        this.props.onUpdateCart(cart);        
    }    

    render() {
        return(             
            <div 
                id="carrinhoMobile"
                className={
                    "context-card context-card--visible " + (this.state.showCartMinimized ? '' : 'context-card--opened') + 
                    ((this.props.carrinho.carrinho_item_quantidade.length + this.props.carrinho.carrinho_item_peso.length) > 0 ? '' : 'display-none')
                } 
            >
                <div className={"context-card__minimized " + (this.props.preload ? 'btn--preload' : '')}>
                    <CarrinhoButton
                        preload={this.props.preload}
                        carrinho={this.props.carrinho}
                        show={this.state.showCartMinimized}
                        onClick={() => this.setState({showCartMinimized: false})}
                    />  
                </div>                            

                <div className={"context-card__maximized " + (this.state.showCartMinimized ? '' : 'context-card__maximized--opened') + " checkout-context-card__maximized"}>
                    <CarrinhoContent
                        preload={this.props.preload}
                        show={!this.state.showCartMinimized}                                            
                                                
                        cliente={this.props.cliente}                        
                        empresa={this.props.empresa}
                        pagamento={this.props.pagamento}
                        modalidadePagamento={this.props.modalidadePagamento}
                        tipoCalculoFrete={this.props.tipoCalculoFrete} 
                        usaCheckout={this.props.usaCheckout}
                        carrinho={this.props.carrinho}                        
                        opcaoEntrega={this.props.opcaoEntrega}                                                    

                        onClick={() => this.setState({showCartMinimized: true})}                        
                        onUpdateCart={(carrinho, item, indiceCarrinhoItem, quantidade) => this.props.onUpdateCart(carrinho, item, indiceCarrinhoItem, quantidade)}                        
                        onUpdateEnderecoEntrega={(endereco, clienteEndereco) => this.props.onUpdateEnderecoEntrega(endereco, clienteEndereco)}
                        onUpdatePagamento={(
                          id,
                          descricao,
                          troco,
                          opcaoPagamento,
                          pagamentoAssincrono,
                          permite_parcelamento
                        ) => 
                          this.props.onUpdatePagamento(
                            id,
                            descricao,
                            troco,
                            opcaoPagamento,
                            pagamentoAssincrono,
                            permite_parcelamento
                          )
                        }
                        refreshCart={() => this.props.refreshCart()}
                        
                        route={(item) => this.props.route(item)}
                    />                    
                </div>
            </div>                    
        )
    }
}

export default Carrinho;
