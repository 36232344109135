import AbstractComponent from "../AbstractComponent";
import CheckoutContentPainel from "./CheckoutContentPainel";
import Fechamento from "./Fechamento";
import Grid from '@material-ui/core/Grid';
import CheckoutEndereco from "./CheckoutEndereco";
import CheckoutResume from "./CheckoutResume";
import CheckoutPagamento from "./CheckoutPagamento";
import CarrinhoEmpty from "../shopping/carrinho-desktop/CarrinhoEmpty";

import Config from "../../Config"

import "./pagamento.css"

class CheckoutContent extends AbstractComponent {    

    voltarHome() {
        window.location.href = Config.urlBase
    }

    showCarrinhoVazio() {
        if (this.props.carrinho.totalizador.total > 0.00) {
            return ;
        }

        return (
            <Grid item sm={12} style={{ textAlign: 'left', mb: 1 }}>
                <CarrinhoEmpty
                    visible={true}
                />

                <Grid item 
                    sm={12}                    
                    style={{ 
                        marginBottom: '1rem', 
                        fontWeight: this.props.fontWeight, 
                        borderTop: this.props.borderTop 
                    }}
                >
                    <button 
                        type="button"
                        className="btn btn--default btn--size-m btn--full-width area-cart-footer__button"
                        style={{
                            background: '#fff',
                            color: 'red',
                            border: '1px solid red',
                            fontSize: '18px',
                            lineHeight: '18px',                            
                        }}
                        onClick={() => this.voltarHome()}                
                    >
                        Ir para as compras
                    </button>
                </Grid>
            </Grid>
        )
    }

    showCheckout() {
        if (this.props.carrinho.totalizador.total === 0.00) {            
            return ;
        }

        return (            
            <>
                <CheckoutContentPainel 
                    sm={8} 
                    titulo="Frete e pagamento" 
                    className="checkout-content-painel-fechamento"
                >                    
                    <Fechamento 
                        cliente={this.props.cliente}
                        empresa={this.props.empresa}
                        cidadesDisponiveis = {this.props.cidadesDisponiveis}
                        opcaoEntrega = {this.props.opcaoEntrega}
                        carrinho={this.props.carrinho}
                        modalidadePagamento={this.props.modalidadePagamento}
                        pagamento={this.props.pagamento}
                        tipoCalculoFrete={this.props.tipoCalculoFrete}
                        fretes={this.props.fretes}

                        inPreload={preload => this.props.inPreload(preload)}
                        onUpdateCart={(cart) => this.props.onUpdateCart(cart)}
                        onUpdateEnderecoEntrega={(endereco, clienteEndereco) => this.props.onUpdateEnderecoEntrega(endereco, clienteEndereco)}
                        onUpdatePagamento={(
                            id,
                            descricao,
                            troco,
                            opcaoPagamento,
                            pagamentoAssincrono,
                            permite_parcelamento
                        ) =>
                            this.props.onUpdatePagamento(
                                id,
                                descricao,
                                troco,
                                opcaoPagamento,
                                pagamentoAssincrono,
                                permite_parcelamento
                            )
                        }
                    />
                </CheckoutContentPainel>

                <CheckoutContentPainel                    
                    sm={4} 
                    className="checkout-content-painel-endereco"
                    titulo="Endereço e resumo do pedido" 
                    background="#F9F9F9"
                >
                    <CheckoutEndereco
                        cidadesDisponiveis = {this.props.cidadesDisponiveis}
                        empresa={this.props.empresa}
                        cliente={this.props.cliente}
                        opcaoEntrega={this.props.opcaoEntrega}
                        carrinho={this.props.carrinho}

                        onUpdateEnderecoEntrega={(endereco, clienteEndereco) => this.props.onUpdateEnderecoEntrega(endereco, clienteEndereco)}
                        onUpdateCart={(carrinho) => this.props.onUpdateCart(carrinho)}
                    />
                </CheckoutContentPainel>

                <CheckoutContentPainel sm={8} className="checkout-content-painel-pagamento">
                    <CheckoutPagamento                        
                        empresa={this.props.empresa}
                        cliente={this.props.cliente}
                        carrinho={this.props.carrinho}
                        pagamento={this.props.pagamento}
                        tipoCalculoFrete={this.props.tipoCalculoFrete}

                        refreshCart={() => this.props.refreshCart()}
                    />
                </CheckoutContentPainel>
                    
                <CheckoutContentPainel 
                    sm={4} 
                    background="#F9F9F9"
                    className="checkout-content-painel-resumo" 
                >
                    <CheckoutResume                        
                        carrinho={this.props.carrinho}                         
                    />
                </CheckoutContentPainel>                                 
            </>            
        );
    }
    
    componentDidMount() {
        if (!this.isLogged()) {
            this.login2();
        }        
    }

    render() {
        return (
            <>
                <Grid container spacing={2} className="checkout-content">
                    { this.showCarrinhoVazio() }

                    { this.showCheckout() }
                </Grid>

                <div style={{height: '49px'}}>

                </div>
            </>
        )
    }
}

export default CheckoutContent;
