import Config from "../Config";
import Requisicao from "./RequisicaoService";

class GetnetService {
    static async auth() {
        return Requisicao.get(Config.urlBaseApi + 'getnet/auth');
    }    
}

export default GetnetService;
