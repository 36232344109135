import React from 'react';
import { Modal } from 'react-bootstrap';
import AbstractComponent from '../../AbstractComponent';

import { setupIframe, setupIframeListener, uninstallIframeListener } from '../../../helper/AdicionarScriptGetnet';
import Config from '../../../Config';

import GetnetService from '../../../service/GetnetService';
import ClienteService from '../../../service/ClienteService';
import FormasPagamentoService from '../../../service/FormasPagamentoService';

import './ModalPagamento.css';

class PreloadPagamentoGetNet extends AbstractComponent { 
    constructor(props) {
        super(props);

        this.state = {
            cliente: {},
            preload: true,            
            installments: 1,
            timeoutId: undefined,
            auth: {}
        }

        this.data = props.carrinho ? props.carrinho : props.pedido

        this.opcoesPagamentoHMG = [
            {
                descricao: "A Vista",
                parcelas: 1,
                valor: this.data.totalizador.total.toFixed(2)
            },
            {
                descricao: "Cartão em até 3x",
                parcelas: 3,
                valor: 12303.03
            },
            {
                descricao: "Cartão em até 4x",
                parcelas: 4,
                valor: 12304.04
            },
            {
                descricao: "Cartão em até 5x",
                parcelas: 5,
                valor: 12305.05
            },
            {
                descricao: "Cartão em até 6x",
                parcelas: 6,
                valor: 12306.06
            },
            {
                descricao: "PIX Aprovado",
                parcelas: 1,
                valor: "90.00"
            },
            {
                descricao: "PIX Reprovado",
                parcelas: 1,
                valor: 90.88
            },
        ];
        this.handleIframeMessage = this.handleIframeMessage.bind(this);
        this.addListener = this.addListener.bind(this);
    }  

    createIFrame(installments, totalCarrinho) {
        setupIframe(this.props, this.state.cliente, installments, totalCarrinho, this.state.auth);

        let timeoutExecuted = false;
        this.setState({ timeoutId: setTimeout(() => {
            if (!timeoutExecuted) {
                timeoutExecuted = true;
                document.querySelector('.pay-button-getnet').click();
                this.props.onPreload(false);
            }
        }, 1500) });
    }

    getnet() {
        GetnetService.auth().then(response => {
            this.setState({ auth: response.data, preload: false })

            if (process.env.REACT_APP_GETNET_ENVIRONMENT !== "DEV") {
                this.createIFrame(this.state.installments, this.data.totalizador.total.toFixed(2));
            }
        }).catch(() => {
            this.props.erro("Erro ao autenticar na gateway de pagamento. Por favor, tente novamente mais tarde.");
        });
    }

    buscaParcelamento() {
        FormasPagamentoService.getParcelas(
            this.props.empresa.id,
            this.props.pagamento.id,
            this.data.totalizador.total
        ).then(resposta => {                      
            this.setState({ installments: resposta.data.length });
            this.getnet();
        }).catch(() => {
            this.props.erro("Erro ao autenticar na gateway de pagamento. Por favor, tente novamente mais tarde.");
        });
    }

    componentDidMount() {
        ClienteService.cliente(this.data.documento).then(response => {
            this.setState({ cliente: response.data });
            this.buscaParcelamento();
        }).catch(() => {
            this.props.erro("Erro ao autenticar na gateway de pagamento. Por favor, tente novamente mais tarde.");
        });
    }

    componentWillUnmount() {
        this.uninstallIframeCheckout();
    }
    
    onClose(message) {
        this.uninstallIframeCheckout();
        this.props.onClose(message);        
    }

    uninstallIframeCheckout() {
        clearTimeout(this.state.timeoutId);
        this.setState({ timeoutId: undefined });
        uninstallIframeListener();
        window.removeEventListener('iframeMessage', this.handleIframeMessage);
    }

    handleIframeMessage(event) {    
        const data = event.detail || '';
        
        switch (data.status || data) {
          case 'success':
              this.onClose("");
              break;

          case 'close':
              this.onClose("Aguardando Pagamento");
              break;

          case 'pending':
              this.onClose("Pagamento Pendente");
              break;

          case 'error':
              this.uninstallIframeCheckout();              
              this.props.erro("Erro ao efetuar o pagamento. Por favor, tente novamente.")
              break;

          default:
              break;
        }
    };

    addListener() {
        setupIframeListener()
        window.addEventListener('iframeMessage', this.handleIframeMessage);
    }

    showPRDEnvironment() {
        if (this.state.preload || process.env.REACT_APP_GETNET_ENVIRONMENT !== "DEV") {
            return (
                <>
                    <img src={Config.urlImgBase + "preload.gif"} width="16px" height="16px" alt="Loading..."/> <br/>
                    Carregando                    
                </>
            )
        }
    }

    showHMGEnvironment() {
        if (!this.state.preload && process.env.REACT_APP_GETNET_ENVIRONMENT === "DEV") {
            return (
                <>
                    <p style={{ 
                        fontSize: '18px',
                        fontWeight: 'bold',
                        color: "red",
                        marginBottom: '2.5rem'
                    }}>
                        Ambiente de Homologação
                        <br />
                        <span style={{ fontSize: '14px' }}>Escolha uma opção abaixo para continuar</span>
                    </p>
                    
                    {
                        this.opcoesPagamentoHMG.map((opcoes, index) => (
                          <button
                              key={index}
                              type={this.props.type}
                              className={"button-escolher pagamento"}
                              onClick={() => this.createIFrame(opcoes.parcelas, opcoes.valor)}                     
                          >                          
                              <div className="col-md-8                                 
                                          col-sm-8                                                    
                                          col-lg-8                                                
                                          col-xs-8">
                                  <span>
                                      { opcoes.descricao }
                                  </span>
                              </div>

                              <div className="col-md-4                                 
                                              col-sm-4                                                
                                              col-lg-4                                                
                                              col-xs-4
                                              text-right">
                                  <span className={"opcao-entrega-info__action escolher"}>
                                      Selecionar
                                  </span>
                              </div>
                          </button>
                        ))
                    }                    
                </>
            )
      }
    }
    
    render() {
        return (      
            <Modal                
                backdrop={'static'}
                show={this.props.show}
                bsSize="small"
                backdropClassName="modal-preload"
                style={{zIndex: '9999'}}
            >
                <Modal.Body style={{color: '#4A6A95', textAlign: 'center'}}> 
                    { this.showPRDEnvironment() }
                    { this.showHMGEnvironment() }

                    <a id="meuLink" type="button" className="pay-button-getnet" hidden={true} onClick={() => this.addListener()}>Realizar pagamento</a>            
                </Modal.Body>
            </Modal>               
        );
    }
}

export default PreloadPagamentoGetNet;
