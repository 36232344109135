var StatusPedidoEnum = {
    AGUARDANDO_PAGAMENTO: {
        enumName:"AGUARDANDO_PAGAMENTO",        
        status: "Aguardando Pagamento",  
        text: "Aguardando Pagamento",
        textTracking: "Aguardando Pagamento",  
        className: "btnStatusWaitingPayment",
        classNameTexto: "color-black",
        trackingClassName: "orderPlaced Disabled",
        trackingProgressBarAtrib: "disabled",        
    },

    PAGAMENTO_NEGADO: {
      enumName:"PAGAMENTO_NEGADO",        
      status: "Pagamento Negado",  
      text: "Pagamento Negado",
      textTracking: "Pagamento Negado",  
      className: "btnStatusDeniedPayment",
      classNameTexto: "",
      trackingClassName: "orderCanceled",      
      trackingProgressBarAtrib: "disabled",        
  },

    PEDIDO_REALIZADO: {
        enumName:"PEDIDO_REALIZADO",        
        status: "Pedido Realizado",  
        text: "Pedido Realizado",
        textTracking: "Realizado",  
        className: "btnStatusOrderPlaced",
        classNameTexto: "",
        trackingClassName: "orderPlaced Disabled",
        trackingProgressBarAtrib: "disabled",        
    },

    PEDIDO_RECEBIDO_PELA_LOJA: {
        enumName:"PEDIDO_RECEBIDO_PELA_LOJA",        
        status: "Pedido Recebido Pela Loja",   
        text: "Pedido Realizado",   
        textTracking: "Realizado",      
        className: "btnStatusOrderPlaced",
        classNameTexto: "",
        trackingClassName: "orderPlaced Disabled",
        trackingProgressBarAtrib: "disabled",        
    },

    PEDIDO_CONFIRMADO: {
        enumName:"PEDIDO_CONFIRMADO",        
        status: "Pedido Confirmado",    
        text: "Pedido Confirmado",           
        textTracking: "Confirmado",  
        className: "btnStatusOrderConfirmed",
        classNameTexto: "",
        trackingClassName: "orderConfirmed Disabled",
        trackingProgressBarAtrib: "disabled",
    },

    EM_SEPARACAO: {
        enumName:"EM_SEPARACAO",        
        status: "Em Separacao", 
        text: "Em Separação",    
        textTracking: "Em Separação",            
        className: "btnStatusInSeparation",
        classNameTexto: "",
        trackingClassName: "inSeparation Disabled",
        trackingProgressBarAtrib: "disabled",
    },

    DOCUMENTO_FISCAL_EMITIDO: {
        enumName:"DOCUMENTO_FISCAL_EMITIDO",        
        status: "Documento Fiscal Emitido",        
        text: "NF/CF Emitido",
        textTracking: "NF/CF Emitido",  
        className: "btnStatusTaxDocumentIssued",
        classNameTexto: "",
        trackingClassName: "taxDocumentIssued Disabled",
        trackingProgressBarAtrib: "disabled",
    },

    EM_TRANSPORTE: {
        enumName:"EM_TRANSPORTE",        
        status: "Em Transporte",       
        text: "Em Transporte",      
        textTracking: "Em Transporte",    
        className: "btnStatusOnCarriage",
        classNameTexto: "",
        trackingClassName: "onCarriage Disabled",
        trackingProgressBarAtrib: "disabled",
    },

    PEDIDO_ENTREGUE: {
        enumName:"PEDIDO_ENTREGUE",        
        status: "Pedido Entregue",        
        text: "Pedido Entregue",    
        textTracking: "Entregue",     
        className: "btnStatusDelivered",
        classNameTexto: "",
        trackingClassName: "orderDelivered Disabled",
        trackingProgressBarAtrib: "",
    },

    PEDIDO_CANCELADO: {
        enumName:"PEDIDO_CANCELADO",        
        status: "Pedido Cancelado",        
        text: "Pedido Cancelado", 
        textTracking: "Cancelado",        
        className: "btnStatusOrderCanceled",
        classNameTexto: "",
        trackingClassName: "orderCanceled",         
        trackingProgressBarAtrib: "",       
    },    

    NOT_DEFINED: {
        enumName:"NOT_DEFINED",        
        status: "",   
        text: "",     
        textTracking: "",  
        className: "btnStatusDisabled",
        classNameTexto: "",
        trackingClassName: "",
        trackingProgressBarAtrib: "disabled",        
    },

    get(value) {        
        if (value === null || value === undefined || value === "undefined")
            return StatusPedidoEnum.NOT_DEFINED;

        var array = Object.values(StatusPedidoEnum);
        var statusPedidoEnumFound = {};

        array.forEach(element => {            
            if(element.status === value) {
                statusPedidoEnumFound = element;      
                return ;          
            }            
        });   
        
        return statusPedidoEnumFound;
    },

    getAll() {
        var array = Object.values(StatusPedidoEnum);        
        let retorno = [];

        array.forEach(element => {
            if((typeof element) === 'function') {
                return ;
            }

            if(element.enumName === StatusPedidoEnum.PEDIDO_RECEBIDO_PELA_LOJA.enumName) {
                return ;
            }

            if(element.enumName === StatusPedidoEnum.PEDIDO_CANCELADO.enumName) {
                return ;
            }
            
            if(element.enumName === StatusPedidoEnum.NOT_DEFINED.enumName) {
                return ;
            }

            retorno.push(element);                
        });

        return retorno;
    }
}

export default StatusPedidoEnum;
