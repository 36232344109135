import React from "react";
import AbstractComponent from "../AbstractComponent";
import Vitrine from "./vitrine/Vitrine";
import CarrinhoDesktop from "./carrinho-desktop/Carrinho";
import CarrinhoMobile from "./carrinho-mobile/Carrinho";
import ConfiguracaoNotificacaoModal from "../notificacao/configuracao/ConfiguracaoNotificacaoModal";

import AutenticarService from "../../service/AutenticarService";
import CarrinhoService from "../../service/CarrinhoService";

class Shopping extends AbstractComponent {
  constructor(props) {
    super(props);

    this.state = {
      preload: true,

      notificationModal: false,
    };
  }

  refreshCart() {
    CarrinhoService.carrinho(
      AutenticarService.getDocumentoCliente(),
      AutenticarService.getIdCarrinho()
    )
      .then((response) => {
        this.setState({
          preload: false,
        });

        this.props.onUpdateCart(response.data, null, null);
      })
      .catch(() => {
        this.setState({
          preload: false,
        });
      });
  }

  componentDidMount() {
    this.onTitulo(AutenticarService.getNomeEmpresa());

    this.refreshCart();
  }

  acessarProdsCampanha(imagemRef){
    this.props.onPressAcessarProdsCampanha(imagemRef);
  }

  render() {    
    return (
      <div id="area-container" className="area-container">
                
        <ConfiguracaoNotificacaoModal
          show={this.props.notificacaoModal}
          fechar={() => this.props.fecharNotificacaoModal()}
        />

        <Vitrine
          onPressAcessarProdsCampanha={(imagemRef) => {this.acessarProdsCampanha(imagemRef)}}
          inPreload={this.props.inPreload}
          vitrinePreload={this.props.vitrinePreload}
          campanha={this.props.campanha}
          campanhaProdutos={this.props.campanhaProdutos}
          carrinho={this.props.carrinho}
          empresa={this.props.empresa}
          vitrine={this.props.vitrine}
          searchKey={this.props.searchKey}
          exibirRodape={this.props.exibirRodape}
          fetchMoreData={() => this.props.fetchMoreData()}
          onUpdateCart={(
            carrinho,
            indiceItem,
            indiceCarrinhoItem,
            quantidade
          ) =>
            this.props.onUpdateCart(
              carrinho,
              indiceItem,
              indiceCarrinhoItem,
              quantidade
            )
          }
          changeQuantidade={(item, quantidade) =>
            this.props.changeQuantidade(item, quantidade)
          }
          onUpdateVitrine={(index) => this.props.onUpdateVitrine(index)}
          onUpdateBadgeFavorito={(tipo, index) =>
            this.props.onUpdateBadgeFavorito(tipo, index)
          }
          route={(item) => this.props.route(item)}
          filtros = {this.props.filtros}
        />

        <CarrinhoDesktop
          cidadesDisponiveis = {this.props.cidadesDisponiveis}
          preload={this.state.preload}
          cliente={this.props.cliente}
          empresa={this.props.empresa}
          pagamento={this.props.pagamento}
          modalidadePagamento={this.props.modalidadePagamento}
          carrinho={this.props.carrinho}
          opcaoEntrega={this.props.opcaoEntrega}
          tipoCalculoFrete={this.props.tipoCalculoFrete}          
          usaCheckout={this.props.usaCheckout}
          onUpdateCart={(carrinho, item, indiceCarrinhoItem, quantidade) =>
            this.props.onUpdateCart(
              carrinho,
              item,
              indiceCarrinhoItem,
              quantidade
            )
          }
          onUpdateEnderecoEntrega={(endereco, clienteEndereco) =>
            this.props.onUpdateEnderecoEntrega(endereco, clienteEndereco)
          }
          onUpdatePagamento={(
            id,
            descricao,
            troco,
            opcaoPagamento,
            pagamentoAssincrono,
            permite_parcelamento
          ) =>
            this.props.onUpdatePagamento(
              id,
              descricao,
              troco,
              opcaoPagamento,
              pagamentoAssincrono,
              permite_parcelamento
            )
          }
          refreshCart={() => this.refreshCart()}
          route={(item) => this.props.route(item)}
        />

        <CarrinhoMobile
          cidadesDisponiveis = {this.props.cidadesDisponiveis}
          preload={this.state.preload}
          cliente={this.props.cliente}
          empresa={this.props.empresa}
          pagamento={this.props.pagamento}
          modalidadePagamento={this.props.modalidadePagamento}
          carrinho={this.props.carrinho}
          tipoCalculoFrete={this.props.tipoCalculoFrete}          
          opcaoEntrega={this.props.opcaoEntrega}
          usaCheckout={this.props.usaCheckout}
          onUpdateCart={(carrinho, item, indiceCarrinhoItem, quantidade) =>
            this.props.onUpdateCart(
              carrinho,
              item,
              indiceCarrinhoItem,
              quantidade
            )
          }
          onUpdateEnderecoEntrega={(endereco, clienteEndereco) =>
            this.props.onUpdateEnderecoEntrega(endereco, clienteEndereco)
          }
          onUpdatePagamento={(
            id,
            descricao,
            troco,
            opcaoPagamento,
            pagamentoAssincrono,
            permite_parcelamento
          ) =>
            this.props.onUpdatePagamento(
              id,
              descricao,
              troco,
              opcaoPagamento,
              pagamentoAssincrono,
              permite_parcelamento
            )
          }
          refreshCart={() => this.refreshCart()}
          route={(item) => this.props.route(item)}
        />
      </div>
    );
  }
}

export default Shopping;
