import React from "react";
import AbstractComponent from "../../AbstractComponent";
import Button from "../../layout/button/Button";
import ModalAlerta from "../../layout/modal/ModalAlerta";
import ModalWarning from "../../layout/modal/ModalWarning";
import ShoppingSuccess from "../ShoppingSuccess";
import ModalPagamento from "./ModalPagamento";
import PreloadPagamentoGetNet from "./PreloadPagamentoGetNet";

import Config from "../../../Config";

import TemProdutoPesavelEnum from "../../../helper/enumerador/TemProdutoPesavelEnum";
import OpcaoPagamentoEnum from "../../../helper/enumerador/OpcaoPagamentoEnum";

import { buscaConfiguracao } from "../../../helper/Validar";
import { gerarPedido, validarPagamento } from "../../../helper/Pagamento";

class FechamentoButton extends AbstractComponent {
  constructor(props) {
    super(props);

    this.state = {
      preload: false,

      success: false,

      atualizeForm: false,

      atualizaCarrinho: false,

      modalProdPesavel: false,

      modalPagamento: false,

      modalAviso: false,

      idPedido: 0,

      mensagemCliente: '',

      mensagemPagamento: '',

      assincrono: 0,

      erro: [],

      cartao: {
        numero: "",
        nome: "",
        expira_em: "",
        cvv: "",
        brand: "",
        parcelamento: 0,
        parcelamento_descricao: "",
      },
    };

    let produtoPesavel = [];

    produtoPesavel.push("Existem produtos pesáveis no carrinho.");
    produtoPesavel.push(
      "O valor total do pedido pode mudar de acordo com a pesagem e/ou disponibilidade de estoque!"
    );

    this.alertaProdutoPesavel = produtoPesavel;
  }

  validar() {
    this.setState({ erro: [] });

    let msgErro = validarPagamento(this.props.carrinho, this.props.pagamento, this.props.usaFreteTransportadora);

    this.setState({ erro: msgErro });

    return msgErro.length === 0    
  }

  onSubmit(e) {
    if (e !== undefined) e.preventDefault();

    if (!this.autenticar()) return;

    if (!this.validar()) return;

    this.setState({
      preload: true,
      erro: [],
    });

    gerarPedido(
        this.props.cliente, 
        this.props.pagamento, 
        this.props.carrinho, 
        this.state.cartao
    ).then((response) => {        
        this.setState({
          preload: this.props.pagamento.modalidade.pagamentoAssincrono === 1,
          success: this.props.pagamento.modalidade.pagamentoAssincrono === 0,
          modalPagamento: false,
          idPedido: response.data.id,
          mensagemCliente: response.data.mensagem_cliente,
        });        
    }).catch((erro) => {
        let msgErro = this.state.erro;
        let atualizeForm = false;
        let atualizaCarrinho = false;

        if (erro.response.status === 402) atualizeForm = true;

        if (erro.response.status === 403) atualizaCarrinho = true;

        msgErro.push("Por favor, corrija os problemas abaixo:");
        msgErro.push(this.trataErro(erro));

        this.setState({
          preload: false,
          erro: msgErro,
          atualizeForm: atualizeForm,
          atualizaCarrinho: atualizaCarrinho,
        });
    });
  }

  onPagamentoOk(cartao) {
    let cartaoNew = this.state.cartao;

    cartaoNew.numero = cartao.numero;
    cartaoNew.nome = cartao.nome;
    cartaoNew.expira_em = cartao.expira_em;
    cartaoNew.cvv = cartao.cvv;
    cartaoNew.parcelamento = cartao.parcelamento;
    cartaoNew.parcelamento_descricao = cartao.parcelamento_descricao;
    cartaoNew.brand = cartao.brand;

    this.setState({ cartao: cartaoNew });

    this.onSubmit();
  }

  fecharPedido() {
    this.setState({ modalProdPesavel: false });

    if (
      (this.props.pagamento.modalidade.opcaoPagamento === OpcaoPagamentoEnum.PAGO_NO_SITE)&&
      (this.props.pagamento.modalidade.pagamentoAssincrono === 0)
    ) {
      this.setState({ modalPagamento: true });

      return;
    }

    this.onSubmit();
  }

  onClick() {
    if (this.props.usaCheckout) {
        window.location.href = Config.urlBase + "checkout";
        return ;
    }

    if (parseInt(this.props.carrinho.entrega.id, 10) === 0) {
      this.props.chooseOpcaoEntrega();

      return;
    }

    this.props.chooseOpcaoPagamento();
  }

  texto() {
    if (this.props.usaCheckout) {
        return "Finalizar"
    }

    if (parseInt(this.props.carrinho.entrega.id, 10) === 0) {
        return "Escolher Opção de Entrega";
    }

    return "Escolher Opção de Pagamento";
  }

  fecharModalAlerta() {
    if (this.state.atualizeForm) this.homePage2();

    if (this.state.atualizaCarrinho) this.props.refreshCart();    

    if (this.state.assincrono === 1) {
        this.showModalPagamento();
    }

    this.setState({
      erro: [],
      atualizeForm: false,
      atualizaCarrinho: false,
      preload: this.state.assincrono === 1,
      assincrono: 0 
    });    
  }

  pegaMensagem() {    
    let mensagemFinal = buscaConfiguracao(
        this.props.empresa.configuracao,
        "Pedido",
        "Mensagem_final",
        ""
    );

    return mensagemFinal;
  }

  fechar() {
    this.homePage2();

    setTimeout(function () {
      this.setState({ success: false });
    }, 5000);
  }

  showButton() {
    if (
      parseInt(this.props.carrinho.entrega.id, 10) === 0 ||
      (this.props.carrinho.entrega.pergunta_forma_pagamento === "Sim" &&
        this.props.pagamento.id === 0)
    ) {
      return (
        <Button
          type="button"
          preload={this.state.preload}
          className="btn btn--default btn--size-m btn--full-width area-cart-footer__button"
          onClick={() => this.onClick()}
        >
          {this.texto()}
        </Button>
      );
    }

    if (this.props.carrinho.tem_produto_pesavel === TemProdutoPesavelEnum.SIM) {
      return (
        <Button
          type="button"
          preload={this.state.preload}
          className="btn btn--default btn--size-m btn--full-width area-cart-footer__button"
          onClick={() => this.setState({ modalProdPesavel: true })}
        >
          Fechar Pedido
        </Button>
      );
    }

    if (
      this.props.pagamento.modalidade.opcaoPagamento ===
      OpcaoPagamentoEnum.PAGO_NO_SITE &&
      this.props.pagamento.modalidade.pagamentoAssincrono === 0
    ) {
      return (
        <Button
          type="button"
          preload={this.state.preload}
          className="btn btn--default btn--size-m btn--full-width area-cart-footer__button"
          onClick={() => this.setState({ modalPagamento: true })}
        >
          Fechar Pedido
        </Button>
      );
    }

    return (
      <>
        <Button
          type={this.pegaMensagem() === '' ? "submit" : "button"}
          onClick={() => {
            if(this.pegaMensagem() !== ''){
                this.setState({ modalAviso: true });
            }
          }}
          preload={this.state.preload}
          className="btn btn--default btn--size-m btn--full-width area-cart-footer__button"
          disabled={this.state.preload}
        >
          Fechar Pedido
        </Button>
        <ModalWarning
          styleText="ml-3"
          header={"Aviso"}
          show={this.state.modalAviso}
          texto={this.pegaMensagem()}
          fechar={() =>
            {
                this.onSubmit();
                this.setState({ modalAviso: false })
            }}
        />
      </>
    );
  }

  showModalPagamento() {
    if (this.props.empresa.id === 0) {
      return;
    }

    if (this.props.pagamento.modalidade.pagamentoAssincrono === 1 && this.state.idPedido > 0) {
      return (
        <PreloadPagamentoGetNet 
          show={this.state.preload}
          pedido_id={this.state.idPedido}
          carrinho={this.props.carrinho}
          empresa={this.props.empresa}
          pagamento={this.props.pagamento}
          erro={(erro) => this.setState({ erro: erro, preload: false, modalPagamento: false, assincrono: 1 })}
          onClose={(mensagemPagamento) => this.setState({ 
            success: true,
            mensagemPagamento: mensagemPagamento
          })}
          onPreload={(preload) => this.setState({preload: preload})}
        />
      );      
    }

    if (!this.state.modalPagamento) {
      return;
    }        

    return (
      <ModalPagamento
        show={true}
        carrinho={this.props.carrinho}
        pagamento={this.props.pagamento}
        empresa={this.props.empresa}
        preload={this.state.preload}
        fechar={() => this.setState({ modalPagamento: false })}
        onPagamentoOk={(cartao) => this.onPagamentoOk(cartao)}
      />
    );
  }

  render() {
    return (
      <div className="area-cart__line_button">
        <ModalAlerta
          styleText="ml-3"
          header={"Erro de validação"}
          show={this.state.erro.length > 0}
          texto={this.state.erro}
          fechar={() => this.fecharModalAlerta()}
        />

        <ModalWarning
          styleText="ml-3"
          header={"Atenção"}
          show={this.state.modalProdPesavel}
          texto={this.alertaProdutoPesavel}
          fechar={() => this.fecharPedido()}
        />

        <ShoppingSuccess
          show={this.state.success}
          idPedido={this.state.idPedido}
          empresa={this.props.empresa}
          mensagemCliente={this.state.mensagemCliente}
          mensagemPagamento={this.state.mensagemPagamento}
          fechar={() => this.fechar()}
          route={(route) => this.props.route(route)}
        />

        {this.showModalPagamento()}

        <form onSubmit={(e) => this.onSubmit(e)}>{this.showButton()}</form>
      </div>
    );
  }
}

export default FechamentoButton;
