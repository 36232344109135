import React, { Fragment } from 'react';
import AbstractComponent from '../../../AbstractComponent';
import ButtonModalidadePagamento from './ButtonModalidadePagamento';

class PagoNoSite extends AbstractComponent {          
    showComponent() {
        if(!this.props.show) {
            return ;
        }

        return (            
            <Fragment>                    
                {this.props.modalidadePagamento.map((grupo, index ) => {
                    return (
                        <ButtonModalidadePagamento
                            md={12}
                            xs={12}
                            sm={12}
                            lg={12}     
                            key={index} 
                            grupo={grupo}  
                            showGrupo={3}

                            onClick={(
                              id,
                              descricao,
                              troco,
                              pagamentoAssincrono,
                              permite_parcelamento
                            ) => 
                              this.props.onUpdatePagamento(
                                id,
                                descricao.toUpperCase(),
                                troco,
                                pagamentoAssincrono,
                                permite_parcelamento
                              )
                            }
                        />                        
                    )
                })} 
            </Fragment>
        )
    }              
        
    render() {
        return(
            <Fragment>
                {this.showComponent()}
            </Fragment> 
        )
    }
}

export default PagoNoSite;
