import React from 'react';
import { Modal } from 'react-bootstrap';
import AbstractComponent from '../../AbstractComponent';
import ModalTitle from '../../layout/modal/ModalTitle';
import Input from '../../layout/input/Input';
import InputLabelMoeda from '../../layout/input/InputLabelMoeda';
import InputNumber from '../../layout/input/InputNumber';
import InputExpiraCredCard from '../../layout/input/InputExpiraCredCard';
import InputCredCard from '../../layout/input/InputCredCard';
import Button from '../../layout/button/Button';
import ImageCredCard from '../../layout/image/ImageCredCard';
import InputSelectParcelamento from './InputSelectParcelamento';

import AmbienteEnum from '../../../helper/enumerador/AmbienteEnum';
import AtivoEnum from  '../../../helper/enumerador/AtivoEnum';

import { validarNumeroCartaoCredito, validarMesAno } from '../../../helper/Validar';
import { getMesAnoAtual } from '../../../helper/Formatar';

import './ModalPagamento.css';

class ModalPagamento extends AbstractComponent {
    constructor(state) {
        super(state);

        this.state = {
            focused: 'number',

            cartao: {
                numero: '',
                nome: '',
                expira_em: '',
                cvv: '',
                parcelamento: 0,
                brand: '',
                parcelamento_descricao: ''
            },            

            erroCartao: {
                numero: '',
                nome: '',
                expira_em: '',
                cvv: '',
                parcelamento: ''               
            }
        }

        if(this.props.empresa.ambiente === AmbienteEnum.DEVELOPMENT) {
            var cartao = this.state.cartao;                        

            cartao.nome = "EM HOMOLOGACAO";
            cartao.expira_em = getMesAnoAtual(false);
            cartao.cvv = "391";

            this.state.cartao = cartao;
        }
    }

    onChange(e) {
        let cartao = this.state.cartao;

        cartao[e.target.name] = e.target.value;
                
        if(e.target.name === 'nome') {
            cartao[e.target.name] = e.target.value.toUpperCase();
        }      

        this.setState({cartao: cartao});
    }    

    onBlurInputSelectParcelamento(value) {    
        let cartao = this.state.cartao;            

        cartao['parcelamento_descricao'] = value;

        this.setState({cartao: cartao});
    }
    
    onUpdateEditarId(id, descricao) {
        let cartao = this.state.cartao;

        cartao['parcelamento'] = id;                
        cartao['parcelamento_descricao'] = descricao;

        this.setState({cartao: cartao});
    }

    onUpdateBrand(card) {
        let cartao = this.state.cartao;

        cartao.brand = card.issuer;

        this.setState({cartao: cartao});
    }
        
    limpaVariavelErro() {
        let erro = this.state.erroCartao;

        erro.numero = '';
        erro.nome = '';
        erro.expira_em = '';            
        erro.cvv = '';        

        this.setState({ erroCartao: erro });
    } 

    onValidaForm() {
        this.limpaVariavelErro();

        let cartao = this.state.cartao;
        let erroCartao = this.state.erroCartao;
        let erro = false;        

        if(this.props.pagamento.modalidade.permite_parcelamento === AtivoEnum.SIM) {
            if(!cartao.parcelamento) {
                erroCartao.parcelamento = 'Informe o campo Parcelamento';
                erro = true;
            }
        }

        if(!cartao.numero) {
            erroCartao.numero = 'Informe o campo Número do Cartão';
            erro = true;
        }

        if(!validarNumeroCartaoCredito(this.props.empresa.ambiente, cartao.numero)) {
            erroCartao.numero = 'Número do Cartão inválido';
            erro = true;
        }

        if(!cartao.nome) {
            erroCartao.nome = 'Informe o campo Nome do Titular do Cartão';
            erro = true;
        }

        if(!cartao.expira_em) {
            erroCartao.expira_em = 'Informe o campo Validade';
            erro = true;
        }

        if(!validarMesAno(cartao.expira_em)) {
            erroCartao.expira_em = 'Validade do cartão está inválida';
            erro = true;
        }

        if(!cartao.cvv) {
            erroCartao.cvv = 'Informe o campo CVV';
            erro = true;
        }        

        if(erro) {
            this.setState({erroCartao: erroCartao});
            return ;
        }        

        this.props.onPagamentoOk(this.state.cartao);
    }        

    render() {
        return (              
            <Modal                 
                className="modal-pagamento"
                show={this.props.show}                    
                style={{ zIndex: '9999' }}
                onHide={() => this.props.fechar()}
            >
                <ModalTitle
                    icon="down"
                    escondeLupa={false}
                    header="PAGAMENTO"

                    fechar={() => this.props.fechar()}                    
                />
                
                <Modal.Body>
                    <div className="container-overflow">          
                        <InputLabelMoeda  
                            className={"label__moeda"}
                            styleValor={{color: 'blue'}}
                            symbol="R$"
                            sobrescrito={false}
                            value={this.props.carrinho.totalizador.total}                            
                        >
                            <strong style={{color: '#333333'}}>
                                Você pagará
                            </strong>
                        </InputLabelMoeda>       

                        <hr />                 

                        <div style={{display: 'flex', flexWrap: 'wrap'}}>                             
                            <ImageCredCard
                                md={6}
                                xs={12}
                                sm={12}
                                lg={6}                                
                                focused={this.state.focused}
                                number={this.state.cartao.numero}
                                name={this.state.cartao.nome}                                
                                expiry={this.state.cartao.expira_em}                                
                                cvc={this.state.cartao.cvv} 
                                
                                onUpdateBrand={(card) => this.onUpdateBrand(card)}
                            />

                            <div className="col-md-6 col-sm-12 col-lg-6 col-xs-12 " style={{marginTop: '10px'}}>  
                                {
                                    this.props.pagamento.modalidade.permite_parcelamento === AtivoEnum.SIM &&                                
                                    <InputSelectParcelamento
                                        md={12}
                                        xs={12}
                                        sm={12}
                                        lg={12}                                                        
                                        nome="parcelamento" 
                                        empresa={this.props.empresa}   
                                        carrinho={this.props.carrinho}                     
                                        pagamento={this.props.pagamento}
                                        value={this.state.cartao.parcelamento}    
                                        defaultValue={this.state.cartao.parcelamento}                                        
                                        erro={this.state.erroCartao.parcelamento}        
                                        defaultFoco={true}                        
                                        
                                        onChange={ e => this.onChange(e) } 
                                        onBlur={(value) => this.onBlurInputSelectParcelamento(value)}
                                        onUpdateEditarId={(id, descricao) => this.onUpdateEditarId(id, descricao)}
                                    />
                                }

                                <InputCredCard
                                    md={12}
                                    xs={12}
                                    sm={12}
                                    lg={12}                                                                        
                                    id="numero"
                                    nome="numero"
                                    empresa={this.props.empresa}                                    
                                    inputRef={ref => this.numero = ref}
                                    value={this.state.cartao.numero}
                                    defaultValue={this.state.cartao.numero}
                                    erro={this.state.erroCartao.numero}
                                    defaultFoco={true}

                                    onChange={e => this.onChange(e)}                                    
                                    onFocus={() => this.setState({focused: 'number'})}
                                />                                                                    
                                
                                <Input
                                    md={12}
                                    xs={12}
                                    sm={12}
                                    lg={12}
                                    id="nome"
                                    nome="nome"
                                    value={this.state.cartao.nome}
                                    defaultValue={this.state.cartao.nome}
                                    erro={this.state.erroCartao.nome}
                                    disabled={(this.props.empresa.ambiente === AmbienteEnum.DEVELOPMENT)}

                                    onChange={e => this.onChange(e)}
                                    onFocus={() => this.setState({focused: 'name'})}
                                >
                                    Nome do Titular do Cartão
                                </Input>                                    
                                
                                <InputExpiraCredCard
                                    md={6}
                                    xs={6}
                                    sm={6}
                                    lg={6}                                    
                                    id="expira_em"
                                    nome="expira_em"                                                                        
                                    value={this.state.cartao.expira_em}
                                    defaultValue={this.state.cartao.expira_em}
                                    erro={this.state.erroCartao.expira_em}
                                    disabled={(this.props.empresa.ambiente === AmbienteEnum.DEVELOPMENT)}

                                    onChange={e => this.onChange(e)}
                                    onFocus={() => this.setState({focused: 'expiry'})}
                                />                                 
                                
                                <InputNumber
                                    md={6}
                                    xs={6}
                                    sm={6}
                                    lg={6}                                    
                                    id="cvv"
                                    nome="cvv"                                                                        
                                    value={this.state.cartao.cvv}
                                    defaultValue={this.state.cartao.cvv}
                                    erro={this.state.erroCartao.cvv}
                                    maxLength={3}
                                    disabled={(this.props.empresa.ambiente === AmbienteEnum.DEVELOPMENT)}

                                    onChange={e => this.onChange(e)}
                                    onFocus={() => this.setState({focused: 'cvc'})}
                                >
                                    CVV
                                </InputNumber>                                                                                                                                          
                            </div>
                        </div>                            
                    </div>                                                                    
                </Modal.Body>

                <Modal.Footer>                                                
                    <Button
                        type="button"                                             
                        className="btn btn--default btn--size-m btn--full-width area-cart-footer__button"
                        background={3}
                        preload={this.props.preload}
                        
                        onClick={() => this.onValidaForm()}
                    >
                        Finalizar Compra
                    </Button>                   
                </Modal.Footer>
            </Modal>                        
        )
    }
}

export default ModalPagamento;
